import { useEffect, useState } from 'react'
import '../App.css'
import PostService from '../API/PostService';
import StudentOverviewHeader from '../components/StudentOverviewHeader'
import { useNavigate } from 'react-router-dom'
import ModalTeacherRegister from '../components/ModalTeacherRegister';
const tgWebApp = window.Telegram.WebApp;

function StudentOverviewPage() {

    const [currentStudent, setCurrentStudent] = useState({})
    const [noTeacher,setNoTeacher] = useState(false)
    const [teacherModal, setTeacherModal] = useState(false)

    const navigate = useNavigate()
    const goTeacherPage = () => navigate(`/teacher/`)

    const goBack = () => navigate('/', {replace: true})

    const BackButton = tgWebApp.BackButton
    BackButton.show()
    BackButton.onClick(goBack)

    const handleLogout = async () => {
        const response = await PostService.logoutTelegram()
        if ( response.status !==200 )
        {
            console.log(response);
        }
        //goBack()
    }

    useEffect( () => {
        const getCurrentStudent = async () => {
            let response = await PostService.getCurrentStudent()

            if ( response.status !==200 )
            {
                return
            }
            setCurrentStudent(response.data)

            response = await PostService.getTeacherMe()

            if ( response.status !== 200 )
            {
                if ( response.response.status === 404 )
                    setNoTeacher(true)
            }
        }

        getCurrentStudent()
    },[])

    return (
        <div className='student-overview-page'>
            <StudentOverviewHeader student={currentStudent} />
            <div className='button-teacher' onClick={noTeacher ? ()=> setTeacherModal(true) : goTeacherPage} >Войти как репетитор</div>
            <div className='button-exit' onClick={handleLogout}>Выйти из аккаунта</div>
            <ModalTeacherRegister active={teacherModal} setActive={setTeacherModal} />
        </div>
    )
}

export default StudentOverviewPage