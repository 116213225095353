import '../App.css'
import { useNavigate } from 'react-router-dom'

const ModalStudentResponded = ( {active, setActive} ) => {

    const navigate = useNavigate()
    const goStudentResponses = () => navigate(`/student/requests`,{replace: true})

    return (
        <div className={active ? 'modal-container active' : 'modal-container'} onClick={() => setActive(false)}>
            <div className="student-responded-content" onClick={e => e.stopPropagation()}>
                <div className="modal-title">Вы откликнулись!</div>
                <div className="modal-text">Отклик отправлен успешно! Когда преподаватель примет заявку мы обменяем Вас контактами в Telegram, уведомление прийдёт автоматически через бота. Отменить или посмотреть статус заявки можно в разделе “Мои заявки”.</div>
                <div className="accept-button" onClick={goStudentResponses}>Мои заявки</div>
            </div>
        </div>
    )
}

export default ModalStudentResponded