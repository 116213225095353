import { useNavigate } from 'react-router-dom'
import PostService from '../API/PostService'
import '../App.css'



function StudentList( { students, listType, onClick } ) {
    return (
        <div className="students-list">
            { students !== undefined && students.length !== 0 ?
                <div>
                    {students.map((student, index) =>
                        <StudentCard cardInit={student} listType={listType} onClick={onClick} />
                    )}
                </div>
                : <div>empty students</div>
            }
        </div>
    )
}

export default StudentList

function StudentCard( {cardInit, listType, onClick } ) {

    const navigate = useNavigate()
    const goStudentPage = () => {
        localStorage.setItem('pickedStudent', JSON.stringify(cardInit))
        navigate(`/student/${cardInit.id}`, {state: cardInit})
    }
    //navigate(`/teacher/${cardInit.teacher.id}`)

    const handleAcceptOrder = async () => {
        const paramObj = {
            "id": cardInit.id,
            "order_status": 'approved'
        }
        console.log(paramObj);
        onClick(paramObj)
        const response = await PostService.responseOrder(paramObj)
        if ( response.status !== 200)
        {}
        else
        {
        }
    }

    const handleCancelOrder = async () => {
        const paramObj = {
            "id": cardInit.id,
            "order_status": 'canceled'
        }
        console.log(paramObj);
        onClick(paramObj)
        const response = await PostService.responseOrder(paramObj)
        if ( response.status !== 200)
        {}
        else
        {
        }
    }

    const handleAcceptRequest = async () => {
        const id = cardInit.id
        console.log(id)
        const response = await PostService.createResponseToTeacherRequest(id)
        if ( response.status !== 200)
        {}
        else
        {
        }
        console.log(response);
    }

    return (
        cardInit !== undefined ?
            <div className="student-card">
                <div className="student-info">
                    {
                        cardInit.name === undefined ?
                        <img className="student-image" src="https://sun9-72.userapi.com/impg/oErJGs22nV9hEBFVaJb265b4mJWkSuQEKSfzQw/8cALmH0jVVE.jpg?size=516x304&quality=96&sign=a857802168baf4230f1521aa91b1745b&type=album" alt="new" />
                        :<img className="student-image" src="https://sun9-72.userapi.com/impg/oErJGs22nV9hEBFVaJb265b4mJWkSuQEKSfzQw/8cALmH0jVVE.jpg?size=516x304&quality=96&sign=a857802168baf4230f1521aa91b1745b&type=album" alt="new" />
                    }
                    <div className="student-creds">
                        <div className="name">{cardInit.name === undefined ? "░░░░░░░░" : cardInit.name }</div>
                        <div className="date">{cardInit.date}</div>
                        <div className="price">Стоимость: {cardInit.price}</div>
                    </div>
                </div>
                {
                    listType === `my-page` ?
                    <div className="texts-body">
                        <div className={cardInit.number_of_lessons === 0 ? 'lessons-info-not' : 'lessons-info'}>{cardInit.number_of_lessons === 0 ? `Занятия не начались` : `Занятия начались`}</div>
                        <div className="science-info">Предмет: <span className="inside-text">{cardInit.science}</span></div>
                        <div className="science-info">Возраст: <span className="inside-text">{cardInit.age}</span></div>
                        <div className="science-info">Цель занятий: <span className="inside-text">{cardInit.target}</span></div>
                        <div className="science-info">Доп. информация: <span className="inside-text">{cardInit.desc}</span></div>
                    </div>
                    :
                    <div className="texts-body">
                        <div className="science-info">Направления: <span className="inside-text">{cardInit.science}</span></div>
                        <div className="science-info">Возраст: <span className="inside-text">{cardInit.age}</span></div>
                        <div className="science-info">Цель занятий: <span className="inside-text">{cardInit.target}</span></div>
                        <div className="science-info">Доп. информация: <span className="inside-text">{cardInit.desc}</span></div>
                    </div>
                }
                
                {
                    listType === `orders` ?
                    <div className="button-container">
                        <div className="button-decline" onClick={handleCancelOrder}>Отклонить</div>
                        <div className="button-accept" onClick={handleAcceptOrder}>Принять</div>
                    </div>
                    :
                    listType === `requests` ?
                    <div className="button-container">
                        <div className="button-decline">Отклонить</div>
                        <div className="button-accept" onClick={handleAcceptRequest}>Принять</div>
                    </div>
                    :
                    <div className="button-container">
                        <div className="button-info" onClick={goStudentPage}>Подробнее</div>
                    </div>
                }
                {
                    listType === `my-page` && cardInit.debt !== 0 ?
                    <div className='debt'>Задолженность по комиссии: {cardInit.debt} ₽</div>
                    :<></>
                }
                
                
            </div>
        : <></>
    )
}