import { useLocation, useNavigate } from 'react-router-dom';
import '../App.css'
import TextareaAutosize from 'react-textarea-autosize'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import { useEffect, useState } from 'react';
import PostService from '../API/PostService';
import LessonList from '../components/LessonList'

const tgWebApp = window.Telegram.WebApp;

function LessonReportPage() {

    const location = useLocation()
    const cardData = location.state 

    const navigate = useNavigate()
    const goBack = (data) => navigate(`/student/${cardData.id}`, {state: data})

    const BackButton = tgWebApp.BackButton
    BackButton.show()
    BackButton.onClick(goBack)

    const [date, setDate] = useState(Date())
    const [time, setTime] = useState('00:00')
    const [price, setPrice] = useState(0)
    
    const handleDate = ( value ) => {
        console.log(value);
        setDate(value)
    }

    const handleTime = ( event ) => {
        console.log( event.target.value )
        setTime(event.target.value)
    }

    const handlePriceChange = ( event ) => {
        console.log( event.target.value )
        setPrice(Number(event.target.value))
    }

    const handleLesson = async () => {
        console.log(Date(date), time, typeof(Date(date)));
        let dateTime = new Date(date)
        const hours = Number(time.slice(0,2))
        const minutes = Number(time.slice(3,5))

        dateTime.setHours(hours,minutes,0)
        console.log(dateTime.toJSON(), price);
        const lessonObj = {
            "student_id": cardData.student_id,
            "date_of_lesson": dateTime.toJSON(),
            "price": price,
            "science_id": cardData.science_id
        }
        console.log('lessonobj',lessonObj);
        let data = {...cardData}
        console.log(data);

        const response = await PostService.createLesson(lessonObj)
        if ( response.status !== 200 )
        {
            console.log(response);
        }
        console.log('response',response.data);
        goBack(data)
    }   

    useEffect(()=>{
    },[])

    return (
        <div className='lesson-report-page'>
            <div className='header'>Создание урока</div>
            <div className='report-title'>Итоговая стоимость занятия</div>
            <div className='price-container'>
                <input className='price-input' type='number' placeholder='Стоимость' value={''+price} onChange={handlePriceChange} />
                <div>рублей</div>
            </div>
        
            <div className='report-title'>Дата занятия</div>
            <Calendar onChange={handleDate} locale='ru-RU' value={date} />
            <div className='report-title'>Время занятия</div>
            <div className='price-container'>
                <input className='price-input' type="time" onChange={handleTime} placeholder='Время' value={time}/>
            </div> 
            <div className='button-send' onClick={handleLesson}>Создать урок</div>
        </div>
    )
}

export default LessonReportPage