import '../App.css'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PostService from '../API/PostService'
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import TextareaAutosize from 'react-textarea-autosize'
import editIcon from '../icons/edit-student.svg'
import { AuthContext } from '../hoc/AuthProvider'
import ModalStudentResponded from '../components/ModalStudentResponded'
import ModalStudentRegister from '../components/ModalStudentRegister'
import axios from 'axios'
import PDFViewer from '../components/PdfViewer'
import VideoPlayer from '../components/VideoPlayer'
const tgWebApp = window.Telegram.WebApp;

function TeacherOverviewPage() {

    const { id } = useParams()

    const [currentTeacher, setCurrentTeacher] = useState({})
    const [sciences, setSciences] = useState([])
    const [isMyPage, setIsMyPage] = useState(false)
    const [studentModal, setStudentModal] = useState(false)
    const [studentRegisterModal, setStudentRegisterModal] = useState(false)
    const [avatarSrc, setAvatarSrc] = useState(null)
    const [videoUrl, setVideoUrl] = useState('')

    const { GOrders, setGOrders, GTeachers } = useContext(AuthContext)

    const navigate = useNavigate()
    const goTeacherEditPage = () => navigate(`/teacher/edit`)

    const goBack = () => navigate('/teacher/', {replace: true})
    const goResponses = () => navigate(`/student/requests`,{replace: true})
    const goReviews = () => navigate(isMyPage ? `/teacher-reviews/me` : `/teacher-reviews/${currentTeacher.id}`, {replace: true})

    const BackButton = tgWebApp.BackButton
    BackButton.show()
    BackButton.onClick(goBack)

    const onStudentClicked = async () => {
        const response = await PostService.getCurrentStudent()

        if ( response.response?.status === 404 )
        {
            setStudentRegisterModal(true)
        }
        else
        {
            goBack()
        }
    }

    const onStudentCreated = async () => {
        goBack()
    }

    const handleToggle = async (e) => {
        let teacherObj = {...currentTeacher}
        teacherObj.is_visible = !e.target.checked
        const response = await PostService.updateTeacher(teacherObj)
        if ( response.status !== 200 )
        {
            console.log(response)
        }
    }

    const handleOrderClick = async () => {
        const response = await PostService.getCurrentStudent()
        console.log(response)
        if ( response.status !== 200 )
        {
            console.log('need to create student');
            return
        }
        console.log(currentTeacher);
        
        const requestObj = {
            "additional_order_text": "",
            "teacher_id": currentTeacher.id,
            "science_id": currentTeacher.science_associations[0].science_id,
            "lessons_target_id": currentTeacher.science_associations[0].lesson_target_ids[0],
            "age_category_id": currentTeacher.science_associations[0].age_ids[0]
        }
        console.log(requestObj);
        const request = await PostService.makeOrder(requestObj)
        console.log(request);
        setStudentModal(true)
        setGOrders([...GOrders, request.data])
    }

    useEffect( () => {
        const getCurrentTeacher = async () => {
            console.log('start of get current teacher ----------------------------------------------');
            
            const isMy = id === undefined
            setIsMyPage(isMy)

            let response = isMy ? await PostService.getTeacherMe() : await PostService.getTeacher(id)
            if ( response.status !==200 )
            {
                return
            }
            const teacher = response.data
            setCurrentTeacher(teacher)
            const scAssociations = response.data.science_associations
            response = await PostService.getTeacherAvatar(teacher.id)
            if ( response.status !==200 )
            {
                
            }
            else
            {
                const base64 = btoa(
                    new Uint8Array(response.data).reduce(
                        (data, byte) => data + String.fromCharCode(byte),
                        ''
                    )
                )
                setAvatarSrc(`data:image/png;base64,${base64}`)
            }

            let scArray = []
            scAssociations.forEach( ( association ) => {
                const scienceName = JSON.parse(localStorage.getItem('GSciences')).find(e => e.id === association.science_id).name
                const statusName = JSON.parse(localStorage.getItem('GStatuses')).find(e => e.id === association.teacher_status).name
                let ageName = ''
                association.age_ids.forEach( (age) => {
                    ageName += JSON.parse(localStorage.getItem('GAges')).find(e => e.id === age).name + ','
                })
                ageName = ageName.slice(0, ageName.length-1)
                let targetName = ''
                association.lesson_target_ids.forEach( (target) => {
                    targetName += JSON.parse(localStorage.getItem('GTargets')).find(e => e.id === target).name + ','
                })
                targetName = targetName.slice(0, targetName.length-1)
                const scObj = {
                    science: scienceName,
                    status: statusName,
                    age: ageName,
                    target: targetName
                }
                scArray.push(scObj)
            })
            setSciences(scArray)
            console.log('end of get current teacher ------------------------------------------------');
        }

        getCurrentTeacher()
    },[])

    useEffect( () => {
        
    }, [GOrders])

    return (
        <div className='teacher-overview-page'>
            { 
                isMyPage ?
                <div className='toggle-container'>
                    <div>Анкета видна только моим ученикам</div>
                    <Toggle defaultChecked={currentTeacher?.is_visible} onChange={handleToggle} />
                </div>
                : <></>
            }
            
            <div className='pictures-container'>
                <img className="avatar-image" src={avatarSrc ? avatarSrc : 'https://sun9-72.userapi.com/impg/oErJGs22nV9hEBFVaJb265b4mJWkSuQEKSfzQw/8cALmH0jVVE.jpg?size=516x304&quality=96&sign=a857802168baf4230f1521aa91b1745b&type=album'} alt='new'/>
                {currentTeacher.video_presentation_link && (
                    <VideoPlayer videoPath={currentTeacher.video_presentation_link} />
                )}
            </div>
            <div className='name-title'>{currentTeacher?.name}</div>
            <div className='rating-container'>
                <div className='rating'>{`Рейтинг: ${currentTeacher?.rating}`}</div>
                <div className='reviews' onClick={goReviews}>Отзывы</div>
            </div>
            { 
                isMyPage ?
                <div className='inside-rating'>Внутренний рейтинг: {currentTeacher?.payment_rating_from_profile}</div>
                : <></>
            }
            { 
                isMyPage ?
                <div className='edit-container'>
                    <div className='edit-title' onClick={goTeacherEditPage}>Редактировать профиль</div>
                    <img src={editIcon} />
                </div>
                : <></>
            }
            {
                sciences.map((sc, index) => 
                    <div className='science-container'>
                    <div>Предмет: <span>{sc?.science}</span></div>
                    <div>Статус: <span>{sc?.status}</span></div>
                    <div>Стаж: <span>{currentTeacher?.experience}</span></div>
                    <div>Цена: <span>{`${currentTeacher?.price} руб/час`}</span></div>
                    <div>Возрастные категории: <span>{sc?.age}</span></div>
                    <div>Направления: <span>{sc?.target}</span></div>
                </div>
                )
            }
            
            <div className='science-container'>
                <div className='about-container'>Обо мне: <span>{currentTeacher?.about_me}</span></div>
            </div>
            
            <div className='diploma-container'>
                <div className='diploma-title'>Дипломы и сертификаты</div>
                <div className='diplomas-list'>
                    {
                        currentTeacher?.certificates !== undefined && currentTeacher.certificates.length !== 0 ?
                            <div>
                                {currentTeacher.certificates.map((cert, index) =>
                                    <PDFViewer certPath={cert} />
                                )}  
                            </div>
                        : <></>
                    }
                </div>
            </div>
            { 
                isMyPage ?
                <div className='button-container'>
                    <div className='button-student' onClick={onStudentClicked}>Войти как ученик</div>
                    <div className='button-exit'>Выйти из аккаунта</div>
                </div>
                :
                GTeachers.find( e=> e.teacher.id === currentTeacher.id) === undefined || GOrders === undefined ?
                <div className='button-container' onClick={handleOrderClick}>
                    <div className='button-student'>Связаться</div>
                </div>
                : 
                <div className='button-container'>
                    <div className='desc'>Вы уже связались с этим преподавателем</div>
                    <div className='button-student' onClick={goResponses}>Мои заявки</div>
                </div>
            }
            <ModalStudentResponded active={studentModal} setActive={setStudentModal} />
            <ModalStudentRegister active={studentRegisterModal} setActive={setStudentRegisterModal} onStudentCreated={onStudentCreated}/>
            
        </div>
    )
}

export default TeacherOverviewPage